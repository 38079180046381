import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SelectedRes } from '../layouts/classes/SelectedRes';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  icons: any = [
    'assets/images/icon/C.png',
    'assets/images/icon/R.png',
    'assets/images/icon/H.png',
    'assets/images/icon/O.png',
  ];

  totalPropertySelected = 0;
  isProgress = false;
  private readonly SELECTION = 'selected_building';
  private selectedRes: SelectedRes;

  constructor(private http: HttpClient, private localStorage: LocalStorage) {}

  setMarkerData(data: any, circleOutsideSelected: any) {
    const icons: any = [
      'assets/images/icon/C.png',
      'assets/images/icon/R.png',
      'assets/images/icon/H.png',
      'assets/images/icon/O.png',
    ];

    const selectBuildingIcons: any = [
      'assets/images/icon/MC.png',
      'assets/images/icon/MR.png',
      'assets/images/icon/MH.png',
      'assets/images/icon/MO.png',
    ];

    for (const building of data) {
      building.resourceStatus = building.entryStatus.replace('_', ' ');

      let icon = '';
      if (circleOutsideSelected.some((id) => id === building.id)) {
        icon = selectBuildingIcons[building.buildingType.id - 1];
      } else {
        icon = icons[building.buildingType.id - 1];
      }
      const images = [];

      if (building.image && building.image.images !== '') {
        building.image.images.split(',').forEach((imageTemp) => {
          if (!imageTemp.startsWith('http')) {
            images.push(
              'https://res-bbmedia-in.s3.ap-south-1.amazonaws.com/' + imageTemp
            );
          } else {
            images.push(imageTemp);
          }
        });
      } else {
        images.push('assets/images/screen.jpg');
        images.push('assets/images/poster.jpg');
        icon = icon.replace('.png', 'O.png');
      }

      const coordinates = building.coordinates.split(',');
      building.lat = coordinates[0];
      building.lng = coordinates[1];
      building.icon = icon;
      building.imagesArray = images;
      building.isSelected = false;

      building.pPoster = 0;
      building.pScreen = 0;
      building.pStandingScreen = 0;
      building.pDualScreen = 0;

      building.pPosterIds = [];
      building.pScreenIds = [];
      building.pStandingScreenIds = [];
      building.pDualScreenIds = [];
    }
  }

  findSalesProperty(
    coordinates: any,
    sDate: any,
    eDate: any,
    propertyName: any,
    cityIds: any,
    regionIds: any,
    stateIds: any,
    propertyManagement: any,
    propertyType: any,
    noOfPoster: any,
    second: any,
    coveredPopulation: any,
    occupancyRate: any,
    category: any,
    pincode: any,
    productTypeId: any,
    circleOutsideSelected: any,
    maleRatio: any,
    femaleRatio: any,
    childrenRatio: any,
    youthRatio: any,
    middleRatio: any,
    srCitizenRatio: any
  ) {
    const data: any = {
      startDate: sDate,
      endDate: eDate,
      showAll: false,
      coordinates,
      entryStatus: ['PERMITTED'],
    };

    if (propertyName && propertyName !== '') {
      data.propertyName = propertyName;
    }

    if (cityIds && cityIds.length) {
      data.cityIds = cityIds;
    }

    if (regionIds.length) {
      data.regionIds = regionIds;
    }

    if (stateIds.length) {
      data.stateIds = stateIds;
    }

    if (propertyManagement.length) {
      data.propertyManagement = propertyManagement;
    }

    if (propertyType.length) {
      data.propertyTypeIds = propertyType;
    }

    data.pointOfLift = noOfPoster;
    data.videoTime = second;

    if (coveredPopulation && coveredPopulation !== '') {
      data.population = coveredPopulation;
    }

    if (occupancyRate && occupancyRate !== '') {
      data.occupancyRate = occupancyRate;
    }

    if (category.length) {
      data.category = category;
    }

    if (pincode !== '') {
      data.pincode = pincode;
    }

    if (productTypeId && productTypeId.length) {
      data.productTypeIds = productTypeId;
    }

    if (circleOutsideSelected && circleOutsideSelected.length) {
      data.circleOutsideSelected = circleOutsideSelected;
    }

    if (maleRatio && maleRatio !== '') {
      data.maleRatio = maleRatio;
    }
    if (femaleRatio && femaleRatio !== '') {
      data.femaleRatio = femaleRatio;
    }
    if (childrenRatio && childrenRatio !== '') {
      data.childrenRatio = childrenRatio;
    }
    if (youthRatio && youthRatio !== '') {
      data.youthRatio = youthRatio;
    }
    if (middleRatio && middleRatio !== '') {
      data.middleRatio = middleRatio;
    }
    if (srCitizenRatio && srCitizenRatio !== '') {
      data.srCitizenRatio = srCitizenRatio;
    }
    return this.http.post(`${environment.API_URL}/sales`, data);
  }

  findPointDetail(buildingId: number, filterData: any) {
    const params = this.createHttpParams(buildingId, filterData);
    return this.http.get(`${environment.API_URL}/sales/point`, { params });
  }

  createHttpParams(buildingId: any, filterData: any) {
    let params = new HttpParams();
    params = params.set('buildingId', buildingId);
    params = params.set('startDate', filterData.startDate);
    params = params.set('endDate', filterData.endDate);
    params = params.set('showAll', false.toString());
    params = params.set('pointOfLift', filterData.noOfPoster);
    params = params.set('videoTime', filterData.second);
    if (filterData.productTypeId && filterData.productTypeId.length) {
      params = params.set('productTypeIds', filterData.productTypeId);
    }
    return params;
  }

  getSelectedResFromMemory(): SelectedRes {
    return this.selectedRes;
  }

  removeSelectedRes(): void {
    this.localStorage.removeItem(this.SELECTION).subscribe(() => {});
  }

  setSelectedRes(): void {
    this.localStorage.removeItem(this.SELECTION).subscribe(() => {
      this.localStorage
        .setItem(this.SELECTION, this.selectedRes)
        .subscribe(() => {});
      this.totalPropertySelected = this.selectedRes.selected.length;
    });
  }

  recoverFromLocalStorage(): void {
    this.localStorage
      .getItem(this.SELECTION)
      .subscribe((selectedRes: SelectedRes) => {
        if (selectedRes) {
          this.selectedRes = selectedRes;
        } else {
          this.selectedRes = new SelectedRes();
          console.warn('There is no storage data.');
        }
        this.totalPropertySelected = this.selectedRes.selected.length;
      });
  }

  createNewSelectedRes() {
    this.selectedRes = new SelectedRes();
  }

  removeProperty(property: any) {
    this.selectedRes.selected = this.selectedRes.selected.filter(
      (p) => p.id !== property.id
    );
    this.setSelectedRes();
  }

  addProperty(property: any) {
    this.selectedRes.selected.push(property);
    this.setSelectedRes();
  }

  selectProperty(property: any) {
    property.isChecked = true;
    property.isIndeterminate = false;
    // count selection
    property.pScreen = property.salesScreen;
    property.pStandingScreen = property.salesStandingScreen;
    property.pPoster = property.salesPoster;
    property.pDualScreen = property.salesDualScreen;

    // ids selection
    property.pPosterIds = property.salesPosterIds;
    property.pScreenIds = property.salesScreenIds;
    property.pStandingScreenIds = property.salesStandingScreenIds;
    property.pDualScreenIds = property.salesDualScreenIds;

    this.removeProperty(property);
    this.addProperty(property);
  }

  resetProperty(property: any) {
    property.isChecked = false;
    property.isIndeterminate = false;

    property.pScreen = 0;
    property.pStandingScreen = 0;
    property.pPoster = 0;
    property.pDualScreen = 0;

    property.pPosterIds = [];
    property.pDualScreenIds = [];
    property.pScreenIds = [];
    property.pStandingScreenIds = [];

    this.removeProperty(property);
  }

  deletePropertyFromMemory() {
    this.selectedRes.selected = [];
    this.setSelectedRes();
  }

  getTotalInformation() {
    const properties = this.getSelectedResFromMemory().selected;

    if (properties.length === 0) {
      return 'Cart is empty';
    }

    let totalScreen = 0;
    let totalStandingScreen = 0;
    let totalPoster = 0;
    let totalDualScreen = 0;
    let totalPopulation = 0;

    properties.forEach((property) => {
      totalScreen += property.pScreenIds.length;
      totalStandingScreen += property.pStandingScreenIds.length;
      totalPoster += property.pPosterIds.length;
      totalDualScreen += property.pDualScreenIds.length;
      totalPopulation += property.population;
    });
    return (
      'Screen - ' +
      totalScreen +
      ', Std. screen - ' +
      totalStandingScreen +
      ', Dual screen - ' +
      totalDualScreen +
      ', Frame poster - ' +
      totalPoster +
      ', Population - ' +
      totalPopulation
    );
  }

  getAllProperties() {
    return this.getSelectedResFromMemory().selected;
  }

  getCartInformation() {
    const properties = this.getSelectedResFromMemory().selected;
    if (properties.length === 0) {
      return '';
    }
    return (
      'Selected properties - ' +
      properties.length +
      ', ' +
      this.getTotalInformation()
    );
  }
}
